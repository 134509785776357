<script setup>
import { onMounted } from "vue";
import pageTitle, { setPageTitle } from "../../utils/pageTitle";
import InvoicesList from "./InvoicesList.vue";
import PageTitleCard from "@/components/PageTitleCard.vue";

onMounted(() => {
  setPageTitle("Invoices");
});
</script>

<template>
  <PageTitleCard
    subtitle="Use this section to manage Invoices."
    justifyCard="space-between"
  />
  <div id="invoices-grid-wrapper">
    <InvoicesList />
  </div>
</template>

<style>
#invoices-grid-wrapper {
  height: 100%;
  padding-top: 2%;
}
</style>
